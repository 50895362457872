<template>
  <div>
    <v-container>
      <v-toolbar color="#0f2e8c" flat><h3 class="white--text">Thông tin cá nhân</h3></v-toolbar>
      <v-card-title>
        <h3>Đổi mật khẩu</h3>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-text-field
                v-model="Password.Old"
                label="Mật khẩu cũ"
                :type="showPassword ? 'text' : 'password'" 
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                counter=true
                :rules="[required('Mật khẩu'), minLength('Mật khẩu', 6)]"
              />
            </v-col>
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-text-field
                v-model="Password.New"
                :type="showPassword ? 'text' : 'password'" 
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                counter=true
                :rules="[required('Mật khẩu'), minLength('Mật khẩu', 6)]"
                label="Mật khẩu mới"
              />
            </v-col>
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-text-field
                :type="showPassword ? 'text' : 'password'" 
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                counter=true
                :rules="[required('Mật khẩu'), minLength('Mật khẩu', 6),rePassword(Password.New)]"
                label="Xác nhận"
              />
            </v-col>
            <v-spacer />
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-btn 
                dark
                block
                color="green"
                :disabled="!valid"
                @click="changePassword"
              >
                Đổi mật khẩu
              </v-btn>
            </v-col>
          </v-row>
          <Loading :status="loadingPass" />
        </v-form>
      </v-card-text>
      <v-card-title>
        <h3>Cập nhật thông tin Bank</h3>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validBank">
          <v-row>
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-text-field
                v-model="Bank.STK"
                label="Số tài khoản"
                :rules="[required('Số tài khoản'), minLength('Số tài khoản', 4)]"
              />
            </v-col>
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-text-field
                v-model="Bank.TenTK"
                label="Tên tài khoản"
                :rules="[required('Tên tài khoản'), minLength('Tên tài khoản', 4)]"
              />
            </v-col>
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-textarea
                v-model="Bank.Bank"
                label="Tên ngân hàng"
                :rules="[required('Tên ngân hàng'), minLength('Tên ngân hàng', 4)]"
              />
            </v-col>
            <v-spacer />
            <v-col
              cols=6
              xl=3
              lg=3
            >
              <v-btn 
                dark
                block
                color="green"
                :disabled="!validBank"
                @click="changeBank"
              >
                Cập nhật
              </v-btn>
            </v-col>
          </v-row>
          <Loading :status="loadingBank" />
        </v-form>
      </v-card-text>
      <v-card-title>
        <h3>Telegram</h3>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validTelegram">
          <v-row>
            <v-col
                cols=6
                xl=3
                lg=3
            >
              <v-text-field
                  v-model="Telegram"
                  label="ID Telegram"
                  :rules="[required('ID Telegram'), minLength('ID Telegram', 4)]"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                  color="blue"
                  href="https://telegra.ph/C%C3%A1ch-l%E1%BA%A5y-ID-Telegram-07-26"
                  target="_blank"
                  small
                  text
              >
                Cách lấy ID Telegram
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
                cols=6
                xl=3
                lg=3
            >
              <v-btn
                  dark
                  block
                  color="green"
                  :disabled="!validTelegram"
                  @click="changeTelegram"
              >
                Cập nhật
              </v-btn>
            </v-col>
          </v-row>
          <Loading :status="loadingBank" />
        </v-form>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import validations from '@/plugins/helper/validations'
import Loading from '@/components/base/Loading.vue'
import {mapGetters } from 'vuex'
export default {
  name: 'MyAccount',
  components: {
    Loading,
  },
  data () {
    return {
      showPassword: false,
      validBank: false,
      validTelegram: false,
      valid: false,
      loadingPass: false,
      loadingBank: false,
      loadingTelegram: false,
      notice: new Status(this.$swal),
      req: new Request('my'),
      Password: {
        Old:'',
        New: '',
      },
      Bank:{
        STK: '',
        TenTK: '',
        Bank: '',
      },
      Telegram: '',
    }
  },
  computed: {
    ...mapGetters(['loginInfo']),
    BankInfo(){
      if(this.loginInfo.Bank === undefined) return {}
      return this.loginInfo.Bank
    }
  },
  watch:{
  },
  methods: {
    ...validations,
    changePassword(){
      this.loadingPass = true
      this.req.update("password",this.Password).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã đổi mật khâu thành công. Hãy đăng nhập lại !!!")
          this.$store.dispatch('AUTH_LOGOUT');
        }else{
          const e = error || "Có lỗi khi đổi mật khẩu !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingPass = false
      })
    },
    changeBank(){
      this.loadingBank = true
      this.req.update("bank",this.Bank).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã cập nhật thông tin ngân hàng !!!")
        }else{
          const e = error || "Có lỗi khi đổi thông tin ngân hàng !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingBank = false
      })
    },
    changeTelegram(){
      this.loadingTelegram = true
      this.req.update("telegram",{telegram:this.Telegram}).then(resp=>{
        return resp.json()
      })
          .then(json=>{
            const {success,error} = json
            if(success){
              this.notice.success("Đã cập nhật thông tin telegram !!!")
            }else{
              const e = error || "Có lỗi khi đổi thông tin telegram !!!"
              this.notice.error(e)
            }
          })
          .catch(e=>{
            this.notice.error(e)
          })
          .finally(()=>{
            this.loadingTelegram = false
          })
    }
  },
  created(){
    this.Bank = this.BankInfo
  },
  mounted() {
    this.Telegram = this.loginInfo.Telegram;
  }
}
</script>
